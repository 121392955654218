import { graphql } from 'gatsby';
import React, { Component } from 'react';
import HeadTags from '../components/global/headTags';
import ExternalArticleList from '../components/magazine/externalArticleList';
import FeaturedArticle from '../components/magazine/featuredArticle';
import FeaturedVideo from '../components/magazine/featuredVideo';
import HorizontalArticleSummary from '../components/magazine/horizontalArticleSummary';
import InlineMailChimpForm from '../components/magazine/inlineMailChimpForm';
import Promo from '../components/magazine/promo';
import SmallFeaturedArticle from '../components/magazine/smallFeaturedArticle';
import SmallVerticleArticleSummary from '../components/magazine/smallVerticleArticleSummary';
import MagazineLayout from '../layouts/magazine-layout';
import translations from '../utils/translations';

class Magazine extends Component {
	render() {
		const {
			title,
			meta_description,
			featured_image,
			articles,
			featured_articles,
			paragraphs,
			secondary_articles
		} = this.props.data.allItemsJson.edges[0].node;

		const mainFeaturedArticle = featured_articles[0];
		const otherFeaturedArticles = featured_articles.slice(1);
		const secondaryFeaturedArticle = secondary_articles[0];
		const secondaryOtherFeaturedArticles = secondary_articles.slice(1);
		const externalArticles = paragraphs.filter(paragraph => paragraph.type === 'external_article').map(paragraph => {
			return { headline: paragraph.text, url: paragraph.external_links[0].url, title: paragraph.external_links[0].title }
		});

		const promos = paragraphs.filter(paragraph => paragraph.type === 'grid_text_callout');
		const linkedVideos = paragraphs.filter(paragraph => paragraph.type === 'article_linked_video');

		return (
			<MagazineLayout {...this.props}>
				<div className="magazine-content-wrapper">
					<HeadTags
						title={title}
						description={meta_description}
						image={featured_image ? featured_image.image.sizes.hero_md : null}
						imageTwitter={
							featured_image ? featured_image.image.sizes.hero_sm : null
						}
						location={this.props.location}
					/>
					<div className="magazine-main-content">
						<div className="main-features">
							<div className="featured-article">
								{mainFeaturedArticle &&
									<div className="feature">
										<FeaturedArticle article={mainFeaturedArticle} />
										<div className="divider" />
									</div>
								}
								{promos.length > 0 &&
									<div className="feature">
										<Promo promo={promos[0]} size="small" />
									</div>
								}
							</div>
							<div className="other-features">
								{linkedVideos.length > 0 &&
									<div className="featured-video">
										<div className="orange-header">{translations.t('featured video')}</div>
										<FeaturedVideo video={linkedVideos[0]} />
										<div className="mobile-divider-large-text" />
									</div>
								}
								{otherFeaturedArticles.map((article, i) => (
									<SmallFeaturedArticle article={article} key={`featured${i}`} />
								))}
							</div>
							<div className="external-articles">
								{externalArticles.length > 0 &&
									<ExternalArticleList articleList={externalArticles} />
								}
							</div>
						</div>
						<div className="large-divider" />
						<div className="secondary-features">
							<div className="featured-article">
								<FeaturedArticle article={secondaryFeaturedArticle} />
								<div className="mobile-divider" />
							</div>
							<div className="vertical-divider" />
							<div className="horizontal-articles">
								{secondaryOtherFeaturedArticles.map((article, i) => (
									<div key={`secondary${i}`}>
										<HorizontalArticleSummary article={article} />
										{(secondaryOtherFeaturedArticles.length !== (i + 1) && article.authors.length > 0) &&
											<div className="mobile-divider-uneven" />
										}
										{(secondaryOtherFeaturedArticles.length !== (i + 1) && article.authors.length === 0) &&
											<div className="mobile-divider-large-text" />
										}
									</div>
								))}
							</div>
						</div>

					</div>
					<div className="mailchimp-wrapper">
						<InlineMailChimpForm {...this.props.data.social.edges[0].node} />
					</div>
					<div className="magazine-main-content">
						<div className="article-list">
							<div className="medium-promo">
								{promos.length > 1 &&
									<Promo promo={promos[1]} size="medium" />
								}
							</div>
							{articles.map((article, i) => (
								<div key={`article${i}`} className="small-article">
									<SmallVerticleArticleSummary article={article} />
									{(articles.length !== (i + 1) && article.authors.length > 0) &&
										<div className="mobile-divider-uneven" />
									}
									{(articles.length !== (i + 1) && article.authors.length === 0) &&
										<div className="mobile-divider-large-text" />
									}
								</div>
							))}
						</div>
					</div>
					{promos.length > 2 &&
						<Promo promo={promos[2]} size="large" />
					}
				</div>
			</MagazineLayout>
		);
	}
}

export default Magazine;

// eslint-disable-next-line
export const magazinePageQuery = graphql`
query magazinePageContentByPath(
		$path: String!,
		$menuId: String!,
		$isEs: Boolean!,
		$idRegex: String!,
		$blog: String!,
		$translations: String!
	) {
	allItemsJson(filter: { path: { eq: $path } }) {
	  edges {
		node {
		  path
		  meta_description
		  title
		  featured_image {
			image {
			  sizes {
				hero_lg
				hero_md
				hero_sm
				original
			  }
			}
		  }
		  featured_articles {
			id
			title
			authors {
			  id
			  name
			}
			created_date
			short_description
			content_type
			category
			path
			image {
			  sizes {
				square_sm
				square_md
				near_square_lg
				near_square_md
				near_square_sm
				original
			  }
			  credit
			  alt_text
			  title
			}
			related_challenge {
			  id
			  title
			  path
			}
			archive {
			  id
			  title
			  path
			}
		  }
		  secondary_articles {
			id
			title
			authors {
			  id
			  name
			}
			created_date
			short_description
			content_type
			category
			path
			image {
			  sizes {
				square_sm
				square_md
				near_square_lg
				near_square_md
				near_square_sm
				original
			  }
			  credit
			  alt_text
			  title
			}
			related_challenge {
			  id
			  title
			  path
			}
			archive {
			  id
			  title
			  path
			}
		  }
		  articles {
			id
			title
			authors {
			  id
			  name
			}
			created_date
			short_description
			content_type
			category
			path
			image {
			  sizes {
				square_sm
				square_md
				near_square_lg
				near_square_md
				near_square_sm
				original
			  }
			  credit
			  alt_text
			  title
			}
			related_challenge {
			  id
			  title
			  path
			}
			archive {
			  id
			  title
			  path
			}
		  }
		  video_embed {
			url
			id
			caption
		  }
		  ...queryParagraphFields
		}
	  }
	}
	social: allItemsJson(filter: { jsonId: { eq: $menuId } }) {
	  edges {
		node {
		  menu_social {
			title
			menu_name
			weight
			url
			class
			target
		  }
		}
	  }
	}
  
	# Index Content Data
	...headerFields @skip(if:$isEs)
	...footerFields @skip(if:$isEs)
	...headerFieldsEs @include(if:$isEs)
	...footerFieldsEs @include(if:$isEs)
	challenges: allItemsJson(
	  filter: {
		content_type: { regex: "/timeline|article|challenge/" }
		jsonId: { regex: $idRegex }
	  }
	) {
	  edges {
		node {
		  title
		  content_type
		  path
		  related_challenge {
			title
			path
		  }
		}
	  }
	}
	pages: allItemsJson(
	  filter: { content_type: { eq: "page" }, jsonId: { regex: $idRegex } }
	) {
	  edges {
		node {
		  title
		  content_type
		  path
		}
	  }
	}
	blog: allItemsJson(filter: { jsonId: { eq: $blog } }) {
	  edges {
		node {
		  title
		  content_type
		  path
		}
	  }
	}
	translations: allItemsJson(filter: { jsonId: { eq: $translations } }) {
	  edges {
		node {
		  translations {
			id
			key
			value
		  }
		}
	  }
	}
	megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
			projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
  }  
`;